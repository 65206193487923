import React from "react";
import { useRouter } from "../../components";
import { SiteLink } from "../../components";

export function BottomDock(props) {
  const links = [
    {
      url: "/about/masa",
      title: "ABOUT",
      icon: "white-about-masa-icon.png",
      iconHover: "about-masa-icon.png",
    },
    {
      url: "/programs",
      title: "PROGRAM",
      icon: "white-program-icon.png",
      iconHover: "program-icon.png",
    },
    {
      url: "/masa-license",
      title: "MASA LICENSE",
      icon: "white-masa-license-icon.png",
      iconHover: "masa-license-icon.png",
    },
    {
      url: "/rays-blog",
      title: "BLOG",
      icon: "white-blog-icon.png",
      iconHover: "blog-icon.png",
    },
    {
      url: "/contact-us",
      title: "CONTACT US",
      icon: "white-contact-us-icon.png",
      iconHover: "contact-us-icon.png",
    },
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  };

  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li ${isCurrent(link.url) ? "selected" : ""}`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
      >
        <div className='icon'>
          <img
            className='normal'
            src={`/assets/navicons/white/${link.icon}`}
            alt={link.title}
          />
          <img
            className='selected'
            src={`/assets/navicons/red/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  return (
    <>
      <nav className='bottomMenu'>
        <hr />
        <ul className='bottomMenu__links'>{linkElts}</ul>
      </nav>
    </>
  );
}
